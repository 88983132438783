import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "./screens/home/home"
import LoginCodes from "./screens/login-codes/login-codes"
import OAuth from "./screens/oauth/oauth"

export default function BasicExample() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/login-codes">
            <LoginCodes />
          </Route>
          <Route exact path="/oauth">
            <OAuth />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
