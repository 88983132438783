import "./styles.scss"
import { useHistory } from "react-router-dom"
import { Grid, Button } from "@material-ui/core"

export default function Home() {
  const history = useHistory()

  const examples = {
    "Login Codes": "/login-codes",
    "OAuth": "/oauth"
  }

  return (
    <div>
      <div className="container">
        <h2>Home</h2>
        <Grid container justify="center" spacing={2}>
          {Object.entries(examples).map( ([k, href]) => <Grid item><Button className="button" variant="contained" href={href}>{k}</Button></Grid>)}
        </Grid>
      </div>
    </div>
  )
}
