import "./styles.scss"
import { useHistory } from "react-router-dom"
import { Grid, Button, CircularProgress, Snackbar } from "@material-ui/core"
import { useCallback, useState } from "react"

export default function OAuth() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const onClickStart = () => {
    window.location.href = "/oauth-redirect"
  }


  const content = useCallback(() => {

    if (loading) {
      return (
        <CircularProgress />
      )
    }

    return (
      <div>
        <Button variant="contained" onClick={onClickStart}>Connect Smerf</Button>
      </div>
    )
  }, [loading])

  return (
    <div>
      <div className="container">
        <h2>OAuth</h2>
        {content()}
        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => setShowSuccess(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <div className="success">
            <h1>Successfully Connected User!!!</h1>
          </div>
        </Snackbar>
      </div>
    </div>
  )
}
