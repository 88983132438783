import axios from "axios";

interface GenerateLoginCodeResponse {
  code: string
  deeplink: string
  base64Image: string
  expiresAt: number
}

export const generateLoginCode = async (gamer_tag?: string, external_platform_user_id?: string, game_avatar_url?: string, skip_game_confirm?: boolean, game_id?: string) => {
  try {
    
    const body = {
      gamer_tag,
      external_platform_user_id,
      game_avatar_url,
      skip_game_confirm,
      game_id
    }
    
    const res = await axios.post<GenerateLoginCodeResponse>("/login-codes/generate", body, {
      headers: {
        accept: "application/json",
      }
    })
    return res.data
  } catch (err) {
    console.log("generateLoginCode err", err)
  }
  return null
}

export const confirmLoginCode = async () => {
  try {
    const res = await axios.post<GenerateLoginCodeResponse>("/login-codes/confirm", null, {
      headers: {
        accept: "application/json",
      }
    })
    return res.data
  } catch (err) {
    console.log("confirmLoginCode err", err)
  }
  return null
}
export const denyLoginCode = async () => {
  try {
    const res = await axios.post<GenerateLoginCodeResponse>("/login-codes/deny", null, {
      headers: {
        accept: "application/json",
      }
    })
    return res.data
  } catch (err) {
    console.log("confirmLoginCode err", err)
  }
  return null
}

export interface SmerfUserDataResponse {
  user_id?: string
  username?: string
  profilePicUrl?: string
  external_platform_user_id?: string
  confirm_code?: string
  status?: string
}

export const getSmerfUserData = async () => {
  try {
    const res = await axios.get<SmerfUserDataResponse>("/login-code-user-data", {
      headers: {
        accept: "application/json",
      }
    })
    return res.data
  } catch (err) {
    console.log("getSmerfUserData err", err)
  }
  return null
}
