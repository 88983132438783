import "./styles.scss"
import { useHistory, useLocation } from "react-router-dom"
import { Grid, Button, CircularProgress, Snackbar, TextField, Checkbox } from "@material-ui/core"
import { useCallback, useState, useMemo } from "react"
import { confirmLoginCode, denyLoginCode, generateLoginCode, getSmerfUserData, SmerfUserDataResponse } from "../../actions"
import { useInterval } from "../../helpers"
import { Label } from "@material-ui/icons"

export default function LoginCodes() {
  const history = useHistory()
  const { search } = useLocation()

  const { game_id, skipGameConfirm: skipGameConfirmDefault } = useMemo(() => {
    const params = new URLSearchParams(search)
    const game_id = params.get("game_id") ?? undefined
    const skipGameConfirmValue = params.get("skipGameConfirm") 
    const skipGameConfirm = skipGameConfirmValue ? skipGameConfirmValue === "true" : undefined
    return { game_id, skipGameConfirm }
  }, [search]);

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState("start")
  const [qrImageSrc, setQRImageSrc] = useState<string>("")
  const [dynamicLink, setDynamicLink] = useState<string>("")
  const [userData, setUserData] = useState<SmerfUserDataResponse | null>(null)
  const [showSuccess, setShowSuccess] = useState(false)

  const [gamerTag, setGamerTag] = useState("")
  const [externalPlatformUserId, setExternalPlatformUserId] = useState("")
  const [gameAvatarUrl, setGameAvatarUrl] = useState("")
  const [skipGameConfirm, setSkipGameConfirm] = useState(skipGameConfirmDefault || false)
  
  // temp solution to get user data from webhook call, should use webhooks :)
  const intervalFunc = async () => {
    const userData = await getSmerfUserData()
    if (userData?.username) {
      setUserData(userData)

      if (!userData?.confirm_code) {
        if (userData?.status === "denied-phone") {
          setStep("start") // should probs notify user that it was canceled from phone, but just reset for now
        } else if (userData?.status === "confirmed") {
          // handle case if skip_game_confirm is true
          setStep("start")
          setShowSuccess(true)
        } else {
          setStep("waiting")
        }
      } else {
        setStep("confirm")
      }

    }

  }
  useInterval(() => {
    intervalFunc().catch(err => {
      console.error("async error in intervalFunc:", err)
  })
  }, ["qrcode", "waiting"].includes(step) ? 1000 : null) // only run during qrcode & waiting steps

  const onClickStart = async () => {
    setLoading(true)
    const res = await generateLoginCode(gamerTag, externalPlatformUserId, gameAvatarUrl, skipGameConfirm, game_id)
    setLoading(false)
    if (!res) return

    const { base64Image, deeplink } = res
    setQRImageSrc(base64Image)
    setDynamicLink(deeplink)
    setStep("qrcode")
  }

  const onClickConnectGame = async () => {
    window.open(dynamicLink)
  }

  const onClickConfirm = async () => {
    setLoading(true)
    const res = await confirmLoginCode()
    setLoading(false)
    setStep("start")
    setShowSuccess(true)
  }

  const onClickDeny= async () => {
    setLoading(true)
    const res = await denyLoginCode()
    setLoading(false)
    setStep("start")
  }


  const content = useCallback(() => {

    if (loading) {
      return (
        <CircularProgress />
      )
    }

    if (step === "start") {
      console.log("skipGameConfirm", skipGameConfirm)
      return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Button variant="contained" onClick={() => {
            onClickStart().catch( err => {
              console.error("async error in login-codes -> onClickStart:", err)
            })
          }}>Connect Smerf</Button>
          <div>
            <h1>Provide values below to connect game account.</h1>
            <h2>If none are provided, it will simply provide Smerf user information</h2>
            <TextField placeholder="Gamer Tag" variant="outlined" value={gamerTag} onChange={e => setGamerTag(e.target.value)} />
            <TextField placeholder="Game User ID" variant="outlined" value={externalPlatformUserId} onChange={e => setExternalPlatformUserId(e.target.value)} />
            <TextField placeholder="Gamer Avatar URL" variant="outlined" value={gameAvatarUrl} onChange={e => setGameAvatarUrl(e.target.value)} />
            <div>
              <b>Skip Game Confirm</b>
              <Checkbox checked={skipGameConfirm} onChange={(_, checked) => setSkipGameConfirm(checked)} disabled={skipGameConfirmDefault} />
            </div>
            <h3>{game_id ? `game_id: ${game_id}` : ""}</h3>
          </div>
        </div>
      )
    }
    else if (step === "qrcode") {
      return (
        <>
          <div>
            <h3>Scan QRCode with Smerf app to connect game account.</h3>
            <img src={qrImageSrc} />
          </div>
          <h1>OR</h1>
          <div>
            <h3>If on Mobile, Press button to connect game account</h3>
            <Button variant="contained" onClick={() => {
              onClickConnectGame().catch(err => {
                console.error("async error in login-codes -> onClickConnectGame:", err)
              })
            }} >Connect Game</Button>
          </div>
        </>
      )
    }
    else if (step === "waiting") {
      const { username, profilePicUrl } = userData || {}
      return (
        <div>
          {profilePicUrl && <img src={profilePicUrl} />}
          <h3>{`Confirm Connection to Smerf for user ${username} on Phone`}</h3>
        </div>
      )
    }
    else if (step === "confirm") {
      const { username, profilePicUrl } = userData || {}
      return (
        <div>
          {profilePicUrl && <img src={profilePicUrl} />}
          <h3>{`Confirm Connection to Smerf for user ${username}`}</h3>
          <div className="buttonContainer">
            <Button variant="contained" color="secondary" onClick={() => {
              onClickDeny().catch(err => {
                console.error("async error in login-codes -> onClickDeny:", err)
              })
            }}>Deny</Button>
            <Button variant="contained" onClick={() => {
              onClickConfirm().catch(err => {
                console.error("async error in login-codes -> onClickConfirm:", err)
              })
            }}>Confirm</Button>
          </div>
        </div>
      )
    }
  }, [step, loading, gamerTag, externalPlatformUserId, gameAvatarUrl, qrImageSrc, onClickStart, userData, game_id])

  return (
    <div>
      <div className="container">
        <h2>Login Codes</h2>
        {content()}
        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => setShowSuccess(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <div className="success">
            <h1>Successfully Connected User!!!</h1>
          </div>
        </Snackbar>
      </div>
    </div>
  )
}
